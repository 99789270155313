/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/*
You can add global styles to this file, and also import other style files
*/

@import "global_variables";

/* Fonts */

@font-face {
  font-family: 'D-DIN';
  src: url('./assets/fonts/D-DIN.eot');
  src: url('./assets/fonts/D-DIN.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/D-DIN.svg#D-DIN') format('svg'),
  url('./assets/fonts/D-DIN.ttf') format('truetype'),
  url('./assets/fonts/D-DIN.woff') format('woff'),
  url('./assets/fonts/D-DIN.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body{
  font-family: 'D-DIN', sans-serif;
  background: #eeeeee;
  font-size: 1.0rem;
}
a{
  color: #da291c;
  text-decoration: underline;
}
.coming-soon-badge{
  background: #e08228;
  height: auto;
  width: 35px;
  font-size: 9px;
  position: absolute;
  top: -14px;
  right: -31px;
  border-radius: 5px;
  padding: 3px 1px 2px 0px;
  text-align: center;
  line-height: 9px;
}
.sidebar-dropdown .title{
  position: relative;
}
.display-none{
  display: none !important;
}
.loader{
  display: block;
  background: rgba(224, 224, 224, 0.75);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  .spinner{
    text-align: center;
    position: relative;
    font-size: 60px;
    top: 46%;
  }
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.aep-theme {
  background: #1e2229;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  //padding-top: 20px;
}

.page-wrapper .page-content > div {
  padding: 20px 40px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 12px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #707372;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #707372;
}
::-webkit-scrollbar-thumb:active {
  background: #707372;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------aep-theme-------------------------------------------------*/

.aep-theme .sidebar-wrapper {
  background: $primary-color-2;
}

.aep-theme .sidebar-wrapper .sidebar-header,
.aep-theme .sidebar-wrapper .sidebar-search,
.aep-theme .sidebar-wrapper .sidebar-menu {
  border-top: 1px solid #3a3f48;
}

.aep-theme .sidebar-wrapper .sidebar-search input.search-menu,
.aep-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}

.aep-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.aep-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.aep-theme .sidebar-wrapper .sidebar-search input.search-menu,
.aep-theme .sidebar-wrapper .sidebar-search .input-group-text,
.aep-theme .sidebar-wrapper .sidebar-brand>a,
.aep-theme .sidebar-wrapper .sidebar-menu ul li a,
.aep-theme .sidebar-footer>a {
  color: $primary-color-4;
}

.aep-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.aep-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.aep-theme .sidebar-wrapper .sidebar-header .user-info,
.aep-theme .sidebar-wrapper .sidebar-brand>a:hover,
.aep-theme .sidebar-footer>a:hover i {
  color: #b8bfce;
}

.page-wrapper.aep-theme.toggled #close-sidebar {
  color: #bdbdbd;
}

.page-wrapper.aep-theme.toggled #close-sidebar:hover {
  color: #ffffff;
}

.aep-theme .sidebar-wrapper ul li:hover a i,
.aep-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.aep-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.aep-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: $primary-color-4;
  text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
}

.aep-theme .sidebar-wrapper .sidebar-menu ul li a i,
.aep-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.aep-theme .sidebar-wrapper .sidebar-search input.search-menu,
.aep-theme .sidebar-wrapper .sidebar-search .input-group-text {
  background: none;
}

.aep-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}

.aep-theme .sidebar-footer {
  background: #3a3f48;
  box-shadow: 0px -1px 5px #282c33;
  border-top: 1px solid #464a52;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding: 0 0 0 277px;
    .container-fluid{
      padding: 0;
    }
  }
}
/*--

/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------widgets----------------*/
.widget-wrapper{
  padding: 20px 40px;
}
.widget-container{
  background: white;
  box-shadow: 1px 0px 7px #c2c1c1;
  .widget-title{
    font-size: 20px;
    padding: 8px 15px;
    background: $primary-color-1;
    color: white;
    &.viz-title{
      background-color: #2a9bd6;
    }
  }
  .widget-body{
    padding: 16px 0px 6px 0px;
    hr{
      width: 96%;
    }
    ul{
      text-decoration: none;
      list-style-type: none;
      position: relative;
      padding: 0;
      li{
        padding: 0 20px 0 20px;
      }
    }
  }
}
.search-selected{
  background-color: rgba(125, 124, 124, 0.65) !important;
  .customer_no{
    color: white !important;
  }
}

td.details-control {
  background: url('https://datatables.net/examples/resources/details_open.png') no-repeat center center;
  cursor: pointer;
}
tr.shown td.details-control {
  background: url('https://datatables.net/examples/resources/details_close.png') no-repeat center center;
}
